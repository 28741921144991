export const SUCCESS_MESSAGE = {
  deadlineModified: 'Deadline modified.',
  interactionCancelled: 'Interaction cancelled and returned to the QA queue.',
  interactionIdCancelled: (id) => `Interaction [${id}] cancelled`,
  transcriptSubmitted: 'Transcript submitted successfully.',
  qaComplete: 'Interaction QA Complete.',
  roleAssigned: (role) => `${role} assigned.`,
  transcriptFeedbackSaved: 'Transcript Feedback Saved',
  interactionFeedbackSaved: 'Interaction Feedback Saved'
};
